class HeroSlideshowWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    updateSlider() {
        const currentEle = this.$element[0]
        const sliderEle = currentEle.querySelector('.slideshow__list')

        if (sliderEle) {
            new Swiper(sliderEle, {
                slidesPerView: 1,
                rewind: true,
                // autoHeight: true,
            })
        }
    }

    onInit() {
        this.updateSlider()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_slideshow',
        HeroSlideshowWidgetHandler
    )
})
